<template>
  <!-- 水表抄表卡 -->
  <div id="app">
    <div class="container">
      <el-row type="flex" :gutter="10">
        <el-col :span="4">
          <el-date-picker
            style="width: 100%"
            v-model="pickerValue"
            type="monthrange"
            range-separator="至"
            start-placeholder="开始月份"
            end-placeholder="结束月份"
            @change="checked"
          >
          </el-date-picker>
        </el-col>
        <el-col :span="1.5">
          <el-button type="primary" @click="getDeviceInfo">查 询</el-button>
        </el-col>
        <!-- 导出excel表格 -->
        <el-col>
          <el-button type="primary" @click="exportExcel()">导出EXCEL</el-button>
        </el-col>
        <!-- 数据来源注明 -->
        <el-col :span="6" :push="0">
          <span style="color: aquamarine; line-height: 40px"
            >注：本表数据来源于水表读数相减</span
          >
        </el-col>
      </el-row>
      <div class="content"
      v-loading="!isShowTable"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <el-table
          v-if="isShowTable"
          ref="table"
          border
          height="100%"
          :data="tableList"
          style="border-radius: 5px; width: 100%"
          empty-text="暂无数据"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column
            align="center"
            label="序号"
            width="57"
            type="index"
          ></el-table-column>
          <el-table-column
            prop="coding"
            align="center"
            label="编号"
            width="90"
          ></el-table-column>
          <el-table-column
            prop="deviceId"
            align="center"
            label="IMEI"
            width="150"
          ></el-table-column>
          <el-table-column
            prop="name"
            align="center"
            label="设备名称"
            width="220"
          ></el-table-column>
          <el-table-column
            align="center"
            v-for="(item, i) in timeList"
            :key="i"
            :label="item"
          >
            <template slot-scope="scope">
              <span>
                {{ scope.row.valueList[i]?scope.row.valueList[i]:0}}m³
              </span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="累计">
            <template slot-scope="scope">
              {{ scope.row.sum }}m³
            </template>
          </el-table-column>
        </el-table>
        <div class="summation">
          合计：(m³)<span>{{ summation.toFixed(2) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DeviceHistoryValue from "@/api/manageApi/DeviceHistoryValue";
import { export_json_to_excel } from "../../../introduce/Export2Excel";

export default {
  components: {},
  props: {},
  data() {
    return {
      isShowTable: false,
      pickerValue: [],
      startTime: "",
      endTime: "",
      tableData: [],
      timeList: [],
      tableList:[],
      summation: 0,
    };
  },
  created() {
    this.setDefaultTime();
    this.getDeviceInfo();
  },
  mounted() {},
  methods: {
    // 设置默认时间
    setDefaultTime(){
      let defaultYear = this.$moment().format('YYYY')
      this.pickerValue[0] = defaultYear + "-01-01"
      this.pickerValue[1] = defaultYear + "-12-31"
      console.log(this.pickerValue);
    },
    // 获得表格数据
    async getDeviceInfo() {
      let obj = {
        typeId: "DXwaterMeter",
        startTime: this.pickerValue[0],
        endTime: this.pickerValue[1],
      };
      // 接收表格设备信息
      const { data: res } = await DeviceHistoryValue.getTypeAttribute(obj);
      console.log(res);
      if (res.code !== 20000) {
        this.$message.error("接收数据失败");
        return;
      }
      this.tableData = res.data.data;
      this.tableData.sort((a,b)=>a.number-b.number)
      this.timeList = this.tableData[0].timeList;
      // 接收表格数据信息
      const { data: res1 } = await DeviceHistoryValue.getMonthWaterData(obj);
      console.log(res1);
      if (res1.code !== 20000) {
        this.$message.error("接收数据失败");
        return;
      }
      let valueData = res1.data.data;
      for (let i = 0; i < this.tableData.length; i++) {
        // 创建新的字段(valueList)
        this.tableData[i]["valueList"] = [];
        for (let j in valueData) {
          for (let k = 0; k < this.tableData[i].timeList.length; k++) {
            for (let p = 0; p < valueData[j].length; p++) {
              if (
                j === this.tableData[i].timeList[k] &&
                this.tableData[i].deviceId === valueData[j][p].deviceId
              ) {
                  this.tableData[i].valueList[k] = valueData[j][p].value;
              }
            }
          }
        }
        // 创建新的字段（sum）表示总和
        this.tableData[i].sum = 0
        for(let k = 0;k<this.tableData[i].valueList.length;k++){
          if(this.tableData[i].valueList[k]){
            this.tableData[i].sum += parseInt(this.tableData[i].valueList[k])
          }
        }
      }
      console.log(this.tableData);
      this.tableList = this.tableData
      this.isShowTable = true;
    },
    // 选择每行触发事件(累加)
    handleSelectionChange(value) {
      this.summation = 0;
      for (let i = 0; i < value.length; i++) {
        this.summation += Number(value[i].sum);
      }
    },
    // 选择时间日期并对其进行格式化
    checked(value) {
      this.pickerValue[0] = this.$moment(value[0]).format(
        "YYYY-MM-DD"
      );
      this.pickerValue[1] = this.$moment(value[1]).format(
        "YYYY-MM-DD"
      );
    },
    // 导出excel表格
    exportExcel() {
      // 给出一个新的数组用来接收time和value,格式化数据
      let newArr = Object.assign(this.tableData);
      for (let i = 0; i < newArr.length; i++) {
        for (let j = 0; j < newArr[i].timeList.length; j++) {
          newArr[i][newArr[i].timeList[j]] = newArr[i].valueList[j];
        }
      }
      // 设置表格
      let tHeaders = ["设备编号", "设备名称", "累计"];
      let filterVals = ["deviceId", "name", "sum"];
      for (const key in this.tableData[0]) {
        for (let i = 0; i < this.tableData[0].timeList.length; i++) {
          if (key == this.tableData[0].timeList[i]) {
            tHeaders.push(key);
            filterVals.push(key);
          }
        }
      }

      require.ensure([], () => {
        const tHeader = tHeaders; // 对应表格输出的中文title
        const filterVal = filterVals; // 对应表格输出的数据

        const data = this.formatJson(filterVal, newArr);

        export_json_to_excel(tHeader, data, "历史报警记录"); // 对应下载文件的名字
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },
 },

  beforeUpdate() {
    //在数据加载完，重新渲染表格,解决样式错乱问题
    this.$nextTick(() => {
      this.$refs["table"].doLayout();
    });
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/css/tableCss.scss";
@font-face {
  font-family: "led regular";
  src: url("../../../assets/font/ds_digital/DS-DIGI.TTF");
}
.container {
  position: relative;
  box-sizing: border-box;
  padding: 90px 10px 0 10px;
  height: 100%;

  .content {
    height: 90%;
    padding: 10px 0;
  }

  .summation {
    padding-left: 15px;
    position: absolute;
    // position: relative;
    bottom: 0px;
    font-size: 20px;
    height: 40px;
    width: 98%;
    line-height: 40px;
    margin: 0;
    background-color: rgba($color: #000a25, $alpha: 0.85);
    color: aqua;
    span {
      font-size: 36px;
      font-family: "led regular";
      position: absolute;
      right: 20px;
    }
  }
}

// 覆盖css文件的效果防止闪动
/deep/.el-table__row:hover {
  color: #72d4dc;
}
/deep/.el-table__body tr:hover > td {
  background-color: rgba($color: #007a91, $alpha: 0.5) !important;
}
</style>